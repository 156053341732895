"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.walletconnect = exports.injected = void 0;
const config_1 = require("@app/config");
const injected_connector_1 = require("@web3-react/injected-connector");
const walletconnect_connector_1 = require("@web3-react/walletconnect-connector");
const RPC_URLS = {
    97: config_1.CONFIG.rpc["97"],
    56: config_1.CONFIG.rpc["56"],
};
exports.injected = new injected_connector_1.InjectedConnector({});
exports.walletconnect = RPC_URLS[56] &&
    new walletconnect_connector_1.WalletConnectConnector({
        rpc: { 56: config_1.CONFIG.rpc["56"] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        chainId: 56,
        supportedChainIds: [56],
    });
exports.default = {
    injected: exports.injected,
    walletconnect: exports.walletconnect,
};
