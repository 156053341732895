"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaginatedDataLoader = exports.useDataLoader = void 0;
const react_1 = require("react");
const useDataLoader = (loader, args, defaultValue) => {
    const [data, setData] = (0, react_1.useState)(defaultValue);
    const [isLoaded, setLoaded] = (0, react_1.useState)(false);
    const [updateFlag, forceUpdate] = (0, react_1.useState)({});
    const [isCompleted, setCompleted] = (0, react_1.useState)(false);
    const [error, setError] = (0, react_1.useState)("");
    let isCanceled = false;
    (0, react_1.useEffect)(() => {
        const loaderWrapper = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const data = yield loader();
                if (!isCanceled) {
                    setData(data);
                    setLoaded(true);
                }
            }
            catch (error) {
                setLoaded(true);
                setError(error.message);
            }
            finally {
                setCompleted(true);
            }
        });
        loaderWrapper();
        return () => {
            isCanceled = true;
        };
    }, [...args, updateFlag]);
    return { data, isLoaded, isCompleted, setData, error, forceReload: () => forceUpdate({}) };
};
exports.useDataLoader = useDataLoader;
const usePaginatedDataLoader = (loader, trackingArgs, defaultState) => {
    const [trigger, setTrigger] = (0, react_1.useState)({});
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isFirstLoadCompleted, setIsFirstLoadCompleted] = (0, react_1.useState)(false);
    const [list, setList] = (0, react_1.useState)([]);
    const [loaderState, setLoaderState] = (0, react_1.useState)(Object.assign({ preSave: true }, defaultState));
    const [error, setError] = (0, react_1.useState)("");
    let isCanceled = false;
    (0, react_1.useEffect)(() => {
        setIsLoading(true);
        const dataLoaderWrapper = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const result = yield loader(loaderState);
                if (isCanceled) {
                    return;
                }
                if (!isFirstLoadCompleted) {
                    setIsFirstLoadCompleted((prevState) => prevState || true);
                }
                setLoaderState(Object.assign(Object.assign({}, loaderState), { page: result.page, total: result.total }));
                setList((list) => (loaderState.preSave ? [...list, ...result.list] : [...result.list]));
            }
            catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        });
        dataLoaderWrapper();
        return () => {
            isCanceled = true;
        };
    }, [...trackingArgs, trigger, loaderState.page, loaderState.size]);
    (0, react_1.useEffect)(() => {
        if (isFirstLoadCompleted) {
            setList([]);
            setLoaderState(Object.assign(Object.assign({ preSave: true }, defaultState), { page: 1 }));
        }
    }, [...trackingArgs]);
    const nextPage = () => setLoaderState(Object.assign(Object.assign({}, loaderState), { page: loaderState.page + 1 }));
    const setPage = (page) => setLoaderState(Object.assign(Object.assign({}, loaderState), { page }));
    return {
        forceReload: () => setTrigger({}),
        list,
        nextPage,
        setPage,
        isFirstLoadCompleted,
        isLoading,
        loaderState,
        error,
        loadedItems: loaderState.page * loaderState.size,
        isLastPage: loaderState.page * loaderState.size >= (loaderState.total || 0) && isFirstLoadCompleted,
    };
};
exports.usePaginatedDataLoader = usePaginatedDataLoader;
