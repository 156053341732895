"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionsPage = void 0;
const collection_edit_form_1 = require("@components/collection-edit-form");
const collection_filter_1 = require("@components/collection-filter");
const grid_1 = require("@components/grid");
const popup_1 = require("@components/popup");
const collection_hooks_1 = require("@hooks/collection.hooks");
const React = __importStar(require("react"));
const react_1 = require("react");
const helper_1 = require("./helper");
const CollectionsPage = (props) => {
    const [filters, setFilters] = (0, react_1.useState)({});
    const [selectedCollection, setSelectedCollection] = (0, react_1.useState)();
    const { list, setPage, loaderState: { total = 0, size }, forceReload, } = (0, collection_hooks_1.useCollectionList)(Object.assign({}, filters), { preSave: false });
    const editCollectionHandler = (0, react_1.useCallback)((collection) => () => setSelectedCollection(collection), []);
    const closeCollectionEditing = (0, react_1.useCallback)(() => {
        setSelectedCollection(undefined);
        forceReload();
    }, []);
    const config = (0, react_1.useMemo)(() => (0, helper_1.getColumnConfig)(editCollectionHandler), [editCollectionHandler]);
    return (React.createElement("div", { className: "p-collections-page" },
        React.createElement(collection_filter_1.CollectionFilter, { filters: filters, setFilters: setFilters }),
        React.createElement(grid_1.Grid, { data: list, onPageChange: setPage, getKey: ({ id }) => id, columns: config, pageSize: size, totalSize: Math.ceil(total / size) }),
        React.createElement(popup_1.Popup, { isOpened: Boolean(selectedCollection), onClose: closeCollectionEditing }, selectedCollection && React.createElement(collection_edit_form_1.EditCollectionForm, { collection: selectedCollection, onClose: closeCollectionEditing }))));
};
exports.CollectionsPage = CollectionsPage;
