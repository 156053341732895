"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTokenUpdater = exports.useTokenList = void 0;
const token_service_1 = require("@app/services/token.service");
const auth_service_1 = require("@app/services/auth.service");
const core_1 = require("@web3-react/core");
const data_loader_hooks_1 = require("./data-loader.hooks");
const react_1 = require("react");
const authService = new auth_service_1.AuthService();
const useTokenList = (filter, loaderState) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => token_service_1.tokenService.getTokenList(filter, page, size), [JSON.stringify(filter), loaderState === null || loaderState === void 0 ? void 0 : loaderState.preSave], Object.assign(Object.assign({}, loaderState), { page: 1, size: 10 }));
exports.useTokenList = useTokenList;
const useTokenUpdater = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    return (0, react_1.useCallback)((updatedToken) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        try {
            const signature = yield authService.getJwt(library, account);
            yield token_service_1.tokenService.updateToken(signature, updatedToken);
        }
        catch (error) {
            console.error(error);
            return;
        }
    }), [library, account]);
};
exports.useTokenUpdater = useTokenUpdater;
