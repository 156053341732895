"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.positiveFieldHandler = exports.integerFieldHandler = void 0;
const integerFieldHandler = (value, valueSetter) => {
    value = value.replace(",", "");
    const numericValue = Math.abs(Number(value));
    const isNegative = value[0] === "-";
    if ((!Number.isNaN(numericValue) && numericValue >= 0) || value === "-") {
        if (numericValue != 0 && numericValue >= 1) {
            value = value.replace(/^0+/, "");
        }
        const dotIndex = value.indexOf(".");
        if (dotIndex > -1) {
            const integer = value.slice(0, dotIndex).replace(/^(0){1,}/, "");
            value = integer;
        }
        valueSetter(isNegative ? `${value}` : value);
    }
};
exports.integerFieldHandler = integerFieldHandler;
const positiveFieldHandler = (value, valueSetter, decimals) => {
    value = value.replace(",", ".");
    const numericValue = Number(value);
    if (!Number.isNaN(numericValue) && numericValue >= 0) {
        if (numericValue != 0 && numericValue >= 1) {
            value = value.replace(/^0+/, "");
        }
        const dotIndex = value.indexOf(".");
        if (dotIndex > -1) {
            const integer = value.slice(0, dotIndex).replace(/^(0){1,}/, "");
            let fractional = value.slice(dotIndex + 1);
            if (decimals !== undefined) {
                fractional = fractional.slice(0, decimals);
            }
            value = `${integer.length > 0 ? integer : "0"}.${fractional}`;
        }
        valueSetter(value);
    }
};
exports.positiveFieldHandler = positiveFieldHandler;
