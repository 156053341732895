"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserUpdater = exports.useUserList = void 0;
const auth_service_1 = require("@app/services/auth.service");
const core_1 = require("@web3-react/core");
const data_loader_hooks_1 = require("./data-loader.hooks");
const react_1 = require("react");
const user_service_1 = require("@app/services/user.service");
const authService = new auth_service_1.AuthService();
const useUserList = (filter, loaderState) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => user_service_1.userService.getUserList(filter, page, size), [JSON.stringify(filter), loaderState === null || loaderState === void 0 ? void 0 : loaderState.preSave], Object.assign(Object.assign({}, loaderState), { page: 1, size: 10 }));
exports.useUserList = useUserList;
const useUserUpdater = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    return (0, react_1.useCallback)((updatedUser) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        try {
            const signature = yield authService.getJwt(library, account);
            yield user_service_1.userService.updateUser(signature, updatedUser);
        }
        catch (error) {
            console.error(error);
            return;
        }
    }), [library, account]);
};
exports.useUserUpdater = useUserUpdater;
