"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.retry = exports.RetryableError = exports.CancelledError = exports.wait = exports.useDebounce = exports.useSingleCallResult = exports.useMultipleContractSingleData = exports.useSingleContractMultipleData = exports.NEVER_RELOAD = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const hooks_1 = require("../application/hooks");
const actions_1 = require("./actions");
const core_1 = require("@web3-react/core");
function isMethodArg(x) {
    return ["string", "number"].indexOf(typeof x) !== -1;
}
function isValidMethodArgs(x) {
    return (x === undefined ||
        (Array.isArray(x) && x.every((xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg)))));
}
const INVALID_RESULT = {
    valid: false,
    blockNumber: undefined,
    data: undefined,
};
// use this options object
exports.NEVER_RELOAD = {
    blocksPerFetch: Infinity,
};
// the lowest level call for subscribing to contract data
function useCallsData(calls, options) {
    const { chainId } = (0, core_1.useWeb3React)();
    const callResults = (0, react_redux_1.useSelector)((state) => state.multicall.callResults);
    const dispatch = (0, react_redux_1.useDispatch)();
    const serializedCallKeys = (0, react_1.useMemo)(() => {
        var _a, _b, _c;
        return JSON.stringify((_c = (_b = (_a = calls === null || calls === void 0 ? void 0 : calls.filter((c) => Boolean(c))) === null || _a === void 0 ? void 0 : _a.map(actions_1.toCallKey)) === null || _b === void 0 ? void 0 : _b.sort()) !== null && _c !== void 0 ? _c : []);
    }, [calls]);
    // update listeners when there is an actual change that persists for at least 100ms
    (0, react_1.useEffect)(() => {
        const callKeys = JSON.parse(serializedCallKeys);
        if (!chainId || callKeys.length === 0)
            return undefined;
        const calls = callKeys.map((key) => (0, actions_1.parseCallKey)(key));
        dispatch((0, actions_1.addMulticallListeners)({
            chainId,
            calls,
            options,
        }));
        return () => {
            dispatch((0, actions_1.removeMulticallListeners)({
                chainId,
                calls,
                options,
            }));
        };
    }, [chainId, dispatch, options, serializedCallKeys]);
    return (0, react_1.useMemo)(() => calls.map((call) => {
        var _a;
        if (!chainId || !call)
            return INVALID_RESULT;
        const result = (_a = callResults[chainId]) === null || _a === void 0 ? void 0 : _a[(0, actions_1.toCallKey)(call)];
        let data;
        if ((result === null || result === void 0 ? void 0 : result.data) && (result === null || result === void 0 ? void 0 : result.data) !== "0x") {
            data = result.data;
        }
        return { valid: true, data, blockNumber: result === null || result === void 0 ? void 0 : result.blockNumber };
    }), [callResults, calls, chainId]);
}
const INVALID_CALL_STATE = {
    valid: false,
    result: undefined,
    loading: false,
    syncing: false,
    error: false,
};
const LOADING_CALL_STATE = {
    valid: true,
    result: undefined,
    loading: true,
    syncing: true,
    error: false,
};
function toCallState(contract, callResult, fragment, latestBlockNumber) {
    var _a;
    if (!callResult)
        return INVALID_CALL_STATE;
    const { valid, data, blockNumber } = callResult;
    if (!valid)
        return INVALID_CALL_STATE;
    if (valid && !blockNumber)
        return LOADING_CALL_STATE;
    if (!fragment || !latestBlockNumber)
        return LOADING_CALL_STATE;
    const success = data && data.length > 2;
    const syncing = (blockNumber !== null && blockNumber !== void 0 ? blockNumber : 0) < latestBlockNumber;
    let result = undefined;
    if (success && data) {
        try {
            result = (_a = contract === null || contract === void 0 ? void 0 : contract.interface) === null || _a === void 0 ? void 0 : _a.decodeFunctionResult(fragment, data);
        }
        catch (error) {
            console.debug("Result data parsing failed", fragment, data);
            return {
                valid: true,
                loading: false,
                error: true,
                syncing,
                result,
            };
        }
    }
    return {
        valid: true,
        loading: false,
        syncing,
        result: result,
        error: !success,
    };
}
// function getEncoder (contract: Contract) {
//   return new utils.Interface()
// }
function useSingleContractMultipleData(contract, methodName, callInputs, options) {
    const fragment = (0, react_1.useMemo)(() => { var _a; return (_a = contract === null || contract === void 0 ? void 0 : contract.interface) === null || _a === void 0 ? void 0 : _a.getFunction(methodName); }, [contract, methodName]);
    const calls = (0, react_1.useMemo)(() => contract && fragment && callInputs && callInputs.length > 0
        ? callInputs.map((inputs) => {
            var _a;
            return {
                address: contract.address,
                callData: (_a = contract === null || contract === void 0 ? void 0 : contract.interface) === null || _a === void 0 ? void 0 : _a.encodeFunctionData(fragment, inputs),
            };
        })
        : [], [callInputs, contract, fragment]);
    const results = useCallsData(calls, options);
    const latestBlockNumber = (0, hooks_1.useBlockNumber)();
    return (0, react_1.useMemo)(() => {
        return results.map((result) => toCallState(contract, result, fragment, latestBlockNumber));
    }, [fragment, contract, results, latestBlockNumber]);
}
exports.useSingleContractMultipleData = useSingleContractMultipleData;
function useMultipleContractSingleData(addresses, contract, methodName, callInputs, options) {
    const fragment = (0, react_1.useMemo)(() => contract.interface.functions[methodName], [contract, methodName]);
    const callData = (0, react_1.useMemo)(() => (fragment && isValidMethodArgs(callInputs) ? contract.encodeFunctionData(fragment, callInputs) : undefined), [callInputs, contract, fragment]);
    const calls = (0, react_1.useMemo)(() => fragment && addresses && addresses.length > 0 && callData
        ? addresses.map((address) => {
            return address && callData
                ? {
                    address,
                    callData,
                }
                : undefined;
        })
        : [], [addresses, callData, fragment]);
    const results = useCallsData(calls, options);
    const latestBlockNumber = (0, hooks_1.useBlockNumber)();
    return (0, react_1.useMemo)(() => {
        return results.map((result) => toCallState(contract, result, fragment, latestBlockNumber));
    }, [fragment, results, latestBlockNumber]);
}
exports.useMultipleContractSingleData = useMultipleContractSingleData;
function useSingleCallResult(contract, methodName, inputs, options) {
    const fragment = (0, react_1.useMemo)(() => { var _a; return (_a = contract === null || contract === void 0 ? void 0 : contract.interface) === null || _a === void 0 ? void 0 : _a.getFunction(methodName); }, [contract, methodName]);
    const calls = (0, react_1.useMemo)(() => {
        var _a;
        return contract && fragment && isValidMethodArgs(inputs)
            ? [
                {
                    address: contract.address,
                    callData: (_a = contract === null || contract === void 0 ? void 0 : contract.interface) === null || _a === void 0 ? void 0 : _a.encodeFunctionData(fragment, inputs),
                },
            ]
            : [];
    }, [contract, fragment, inputs]);
    const result = useCallsData(calls, options)[0];
    const latestBlockNumber = (0, hooks_1.useBlockNumber)();
    return (0, react_1.useMemo)(() => {
        return toCallState(contract, result, fragment, latestBlockNumber);
    }, [result, fragment, latestBlockNumber]);
}
exports.useSingleCallResult = useSingleCallResult;
// modified from https://usehooks.com/useDebounce/
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = (0, react_1.useState)(value);
    (0, react_1.useEffect)(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}
exports.useDebounce = useDebounce;
function wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
exports.wait = wait;
function waitRandom(min, max) {
    return wait(min + Math.round(Math.random() * Math.max(0, max - min)));
}
/**
 * This error is thrown if the function is cancelled before completing
 */
class CancelledError extends Error {
    constructor() {
        super("Cancelled");
    }
}
exports.CancelledError = CancelledError;
/**
 * Throw this error if the function should retry
 */
class RetryableError extends Error {
}
exports.RetryableError = RetryableError;
/**
 * Retries the function that returns the promise until the promise successfully resolves up to n retries
 * @param fn function to retry
 * @param n how many times to retry
 * @param minWait min wait between retries in ms
 * @param maxWait max wait between retries in ms
 */
function retry(fn, { n, minWait, maxWait }) {
    let completed = false;
    let rejectCancelled;
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
        rejectCancelled = reject;
        // eslint-disable-next-line no-constant-condition
        while (true) {
            let result;
            try {
                result = yield fn();
                if (!completed) {
                    resolve(result);
                    completed = true;
                }
                break;
            }
            catch (error) {
                if (completed) {
                    break;
                }
                if (n <= 0 || !(error instanceof RetryableError)) {
                    reject(error);
                    completed = true;
                    break;
                }
                n--;
            }
            yield waitRandom(minWait, maxWait);
        }
    }));
    return {
        promise,
        cancel: () => {
            if (completed)
                return;
            completed = true;
            rejectCancelled(new CancelledError());
        },
    };
}
exports.retry = retry;
