"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokensPage = void 0;
const grid_1 = require("@components/grid");
const popup_1 = require("@components/popup");
const token_edit_form_1 = require("@components/token-edit-form");
const token_hooks_1 = require("@hooks/token.hooks");
const React = __importStar(require("react"));
const react_1 = require("react");
const helper_1 = require("./helper");
const token_filter_1 = require("@components/token-filter");
const TokensPage = (props) => {
    const [filters, setFilters] = (0, react_1.useState)({});
    const [selectedTokenId, setSelectedTokenId] = (0, react_1.useState)();
    const { list, setPage, loaderState: { total = 0, size }, forceReload, } = (0, token_hooks_1.useTokenList)(Object.assign({}, filters), { preSave: false });
    const selectedToken = (0, react_1.useMemo)(() => { var _a; return (_a = list.find((token) => token.tokenId === selectedTokenId)) === null || _a === void 0 ? void 0 : _a.token; }, [selectedTokenId]);
    const closeTokenEditing = (0, react_1.useCallback)(() => {
        setSelectedTokenId(undefined);
        forceReload();
    }, []);
    const config = (0, react_1.useMemo)(() => (0, helper_1.getColumnConfig)(setSelectedTokenId), [setSelectedTokenId]);
    return (React.createElement("div", { className: "p-tokens-page" },
        React.createElement(token_filter_1.TokenFilter, { setFilters: setFilters }),
        React.createElement(grid_1.Grid, { data: list, onPageChange: setPage, getKey: ({ tokenId }) => tokenId, columns: config, pageSize: size, totalSize: Math.ceil(total / size) }),
        React.createElement(popup_1.Popup, { isOpened: Boolean(selectedToken), onClose: closeTokenEditing }, selectedToken && React.createElement(token_edit_form_1.EditTokenForm, { token: selectedToken, onClose: closeTokenEditing }))));
};
exports.TokensPage = TokensPage;
