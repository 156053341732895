"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditCollectionForm = void 0;
const button_1 = require("@components/button");
const text_input_1 = require("@components/text-input");
const textarea_input_1 = require("@components/textarea-input");
const collection_hooks_1 = require("@hooks/collection.hooks");
const useForm_1 = require("@hooks/useForm");
const validation_1 = require("@utils/validation");
const React = __importStar(require("react"));
require("./styles.scss");
const EditCollectionForm = (props) => {
    const { collection, onClose } = props;
    const collectionUpdater = (0, collection_hooks_1.useCollectionUpdater)();
    const { form, fieldHandlers, validationResults, submit } = (0, useForm_1.useForm)({
        initialData: { title: collection.title, description: collection.description || "" },
        submit: (form, validationResults) => __awaiter(void 0, void 0, void 0, function* () {
            if (validationResults.isValid) {
                yield collectionUpdater({ title: form.title, description: form.description, id: collection.id });
                onClose();
            }
        }),
        validators: {
            title: [
                (0, validation_1.createMaxLengthValidator)(100, "Title length must not exceed 100 characters"),
                (0, validation_1.createRequiredValidator)("This field is required"),
            ],
            description: [
                (0, validation_1.createMaxLengthValidator)(2000, "Title length must not exceed 2000 characters"),
                (0, validation_1.createRequiredValidator)("This field is required"),
            ],
        },
    });
    return (React.createElement("div", { className: "c-collection-edit-form" },
        React.createElement(text_input_1.TextInput, { label: "Title", type: "string", value: form.title, onChangeValue: fieldHandlers.title, error: validationResults.results.title }),
        React.createElement(textarea_input_1.TextareaInput, { label: "Description", value: form.description, onChangeValue: fieldHandlers.description, error: validationResults.results.description, rows: 5 }),
        React.createElement("div", { className: "c-collection-edit-form__button" },
            React.createElement(button_1.Button, { type: "secondary", children: "Cancel", onClick: onClose }),
            React.createElement(button_1.Button, { type: "secondary", children: "Update", onClick: submit }))));
};
exports.EditCollectionForm = EditCollectionForm;
