"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONNECTORS = exports.ConnectorNames = void 0;
const connectors_1 = require("@utils/connectors");
var ConnectorNames;
(function (ConnectorNames) {
    ConnectorNames["MetaMask"] = "MetaMask";
    ConnectorNames["WalletConnect"] = "WalletConnect";
})(ConnectorNames = exports.ConnectorNames || (exports.ConnectorNames = {}));
exports.CONNECTORS = {
    [ConnectorNames.MetaMask]: connectors_1.injected,
    [ConnectorNames.WalletConnect]: connectors_1.walletconnect,
};
