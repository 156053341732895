"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAfterThanValidator = exports.createBeforeThanValidator = exports.createNonZeroNumberValidator = exports.createEqualValidator = exports.createAddressValidator = exports.createEmailValidator = exports.createMinLengthValidator = exports.createEmptyValidator = exports.createArrayValidator = exports.createRequiredValidator = exports.createLessThanValidator = exports.createLessOrEqualThanValidator = exports.createGreatOrEqualThanValidator = exports.createGreatThanValidator = exports.createMaxLengthValidator = void 0;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
const createMaxLengthValidator = (maxLength, message) => (str) => str.length > maxLength && message;
exports.createMaxLengthValidator = createMaxLengthValidator;
const createGreatThanValidator = (bound, message) => (str) => Boolean(str) && !(Number(str) > bound) && message;
exports.createGreatThanValidator = createGreatThanValidator;
const createGreatOrEqualThanValidator = (bound, message) => (str) => Boolean(str) && !(Number(str) >= bound) && message;
exports.createGreatOrEqualThanValidator = createGreatOrEqualThanValidator;
const createLessOrEqualThanValidator = (bound, message) => (str) => Boolean(str) && !(Number(str) <= bound) && message;
exports.createLessOrEqualThanValidator = createLessOrEqualThanValidator;
const createLessThanValidator = (bound, message) => (str) => Boolean(str) && !(Number(str) < bound) && message;
exports.createLessThanValidator = createLessThanValidator;
const createRequiredValidator = (message) => (value) => !Boolean(value) && message;
exports.createRequiredValidator = createRequiredValidator;
const createArrayValidator = (length, message) => (value) => !(Boolean(value) && value.length === length) && message;
exports.createArrayValidator = createArrayValidator;
const createEmptyValidator = (message) => (str) => Boolean(str) && message;
exports.createEmptyValidator = createEmptyValidator;
const createMinLengthValidator = (minLength, message) => (str) => str.length < minLength && message;
exports.createMinLengthValidator = createMinLengthValidator;
const createEmailValidator = (message) => (str) => !EMAIL_REGEX.test(String(str).toLowerCase()) && message;
exports.createEmailValidator = createEmailValidator;
const createAddressValidator = (message) => (str) => !ADDRESS_REGEX.test(String(str).toLowerCase()) && message;
exports.createAddressValidator = createAddressValidator;
const createEqualValidator = (value, message) => (str) => value !== str && message;
exports.createEqualValidator = createEqualValidator;
const createNonZeroNumberValidator = (message) => (str) => !Boolean(Number(str)) && message;
exports.createNonZeroNumberValidator = createNonZeroNumberValidator;
const createBeforeThanValidator = (bound, message) => (str) => Boolean(str) && !(new Date(str).getTime() < bound.getTime()) && message;
exports.createBeforeThanValidator = createBeforeThanValidator;
const createAfterThanValidator = (bound, message) => (str) => Boolean(str) && !(new Date(str).getTime() > bound.getTime()) && message;
exports.createAfterThanValidator = createAfterThanValidator;
