"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useForm = void 0;
const react_1 = require("react");
const validateForm = (form, validatorMap, isSubmitted) => {
    const validationResult = {
        isValid: true,
        results: {},
    };
    const validatorSet = typeof validatorMap === "function" ? validatorMap(form) : validatorMap;
    Object.entries(validatorSet).forEach(([name, validators]) => {
        if (!validators) {
            return;
        }
        for (const validator of validators) {
            if (!validator) {
                continue;
            }
            const property = name;
            const errorMessage = validator(form[property], form);
            if (errorMessage) {
                validationResult.results[property] = isSubmitted ? errorMessage : "";
                validationResult.isValid = false;
                break;
            }
        }
    });
    return validationResult;
};
const useForm = (setting) => {
    const [isSubmit, setIsSubmit] = (0, react_1.useState)(setting.isSubmitted || false);
    const [form, setForm] = (0, react_1.useState)(setting.initialData);
    const resetForm = (form) => {
        var _a;
        setIsSubmit(false);
        setForm(form);
        (_a = setting.onUpdate) === null || _a === void 0 ? void 0 : _a.call(setting, form);
    };
    const validationResults = setting.validators
        ? validateForm(form, setting.validators, isSubmit)
        : { isValid: true, results: {} };
    const fieldHandlers = Object.keys(form).reduce((acc, prop) => {
        const property = prop;
        const customHandler = setting.handlers && setting.handlers[property];
        acc[property] = (value) => {
            var _a;
            if (customHandler) {
                value = customHandler(value);
            }
            setForm(Object.assign(Object.assign({}, form), { [prop]: value }));
            (_a = setting.onUpdate) === null || _a === void 0 ? void 0 : _a.call(setting, Object.assign(Object.assign({}, form), { [prop]: value }));
            return value;
        };
        return acc;
    }, {});
    const submit = (args) => {
        var _a;
        setIsSubmit(true);
        return (_a = setting.submit) === null || _a === void 0 ? void 0 : _a.call(setting, form, validationResults, resetForm, args);
    };
    const createSubmit = (args) => () => submit(args);
    const validate = (validators) => {
        const newValidatorsMap = Object.assign({}, setting.validators);
        Object.entries(validators).forEach(([name, newValidators]) => {
            const property = name;
            newValidatorsMap[property] = [...(newValidatorsMap[property] || []), ...((newValidators === null || newValidators === void 0 ? void 0 : newValidators[property]) || [])];
        });
        return validateForm(form, newValidatorsMap, isSubmit);
    };
    return {
        isSubmit,
        form,
        validationResults,
        fieldHandlers,
        resetForm,
        submit,
        createSubmit,
        validate,
    };
};
exports.useForm = useForm;
