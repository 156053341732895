"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interactions = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("./actions");
var Interactions;
(function (Interactions) {
    Interactions[Interactions["none"] = 0] = "none";
    Interactions[Interactions["pending"] = 1] = "pending";
    Interactions[Interactions["complete"] = 2] = "complete";
    Interactions[Interactions["error"] = 3] = "error";
})(Interactions = exports.Interactions || (exports.Interactions = {}));
const initialState = {
    blockNumber: {},
    popupList: [],
    interactionMode: Interactions.none,
    interactionText: "",
    account: "",
};
exports.default = (0, toolkit_1.createReducer)(initialState, (builder) => builder
    .addCase(actions_1.updateBlockNumber, (state, action) => {
    const { chainId, blockNumber } = action.payload;
    if (typeof state.blockNumber[chainId] !== "number") {
        state.blockNumber[chainId] = blockNumber;
    }
    else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId]);
    }
})
    .addCase(actions_1.addPopup, (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
    state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
            key: key || (0, toolkit_1.nanoid)(),
            show: true,
            content,
            removeAfterMs,
        },
    ]);
})
    .addCase(actions_1.removePopup, (state, { payload: { key } }) => {
    state.popupList.forEach((p) => {
        if (p.key === key) {
            p.show = false;
        }
    });
})
    .addCase(actions_1.setTxInteraction, (state, { payload: { mode, text } }) => {
    state.interactionMode = mode;
    if ([Interactions.none, Interactions.error].includes(mode))
        state.interactionText = text !== null && text !== void 0 ? text : "";
    else if (text)
        state.interactionText = text;
})
    .addCase(actions_1.setAccount, (state, { payload }) => {
    state.account = payload.account;
}));
