"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletForm = void 0;
const React = __importStar(require("react"));
const connector_1 = require("../connector");
const helper_1 = require("./helper");
require("./styles.scss");
const WalletForm = (props) => {
    const { activateConnector } = props;
    const [activatingConnectorName, setActivatingConnectorName] = React.useState();
    // @ts-ignore: next-line
    const isMetamaskAvailable = window.ethereum && window.ethereum.isMetaMask;
    const availableConnectors = Object.entries(helper_1.CONNECTORS).filter(([name]) => isMetamaskAvailable ? true : name !== helper_1.ConnectorNames.MetaMask);
    const createActivateHandler = React.useCallback((connector, name) => () => {
        setActivatingConnectorName(name);
        activateConnector(connector);
    }, []);
    return (React.createElement("div", { className: "c-header-wallet-form" },
        React.createElement("h1", null, "Connect"),
        React.createElement("div", { className: "c-header-wallet-form__buttons" }, availableConnectors.map(([name, connector]) => {
            const activating = name === activatingConnectorName;
            return (React.createElement("div", { key: name, className: "c-header-wallet-form__button" },
                React.createElement(connector_1.Connector, { loading: activating, label: name, onClick: createActivateHandler(connector, name) })));
        }))));
};
exports.WalletForm = WalletForm;
