"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFilter = void 0;
const button_1 = require("@components/button");
const text_input_1 = require("@components/text-input");
const useForm_1 = require("@hooks/useForm");
const React = __importStar(require("react"));
require("./styles.scss");
const UserFilter = (props) => {
    const { filters, setFilters } = props;
    const { form, fieldHandlers, submit } = (0, useForm_1.useForm)({
        initialData: {
            wallet: filters.wallet,
        },
        submit: (form) => {
            setFilters(Object.assign(Object.assign({}, filters), { wallet: form.wallet }));
        },
    });
    return (React.createElement("div", { className: "c-user-filter" },
        React.createElement(text_input_1.TextInput, { placeholder: "Enter wallet", label: "Wallet", value: form.wallet, onChangeValue: fieldHandlers.wallet }),
        React.createElement(button_1.Button, { type: "secondary", onClick: submit }, "Search")));
};
exports.UserFilter = UserFilter;
