"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useActivePopups = exports.useRemovePopup = exports.useAddPopup = exports.useSetWalletInteractions = exports.useBlockNumber = void 0;
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const actions_1 = require("./actions");
const reducer_1 = require("./reducer");
function useBlockNumber() {
    const { chainId } = (0, core_1.useWeb3React)();
    return (0, react_redux_1.useSelector)((state) => state.application.blockNumber[chainId !== null && chainId !== void 0 ? chainId : -1]);
}
exports.useBlockNumber = useBlockNumber;
function useSetWalletInteractions() {
    const dispatch = (0, react_redux_1.useDispatch)();
    const setPending = (0, react_1.useCallback)((text) => dispatch((0, actions_1.setTxInteraction)({ mode: reducer_1.Interactions.pending, text })), [dispatch]);
    const setComplete = (0, react_1.useCallback)((text) => dispatch((0, actions_1.setTxInteraction)({ mode: reducer_1.Interactions.complete, text })), [dispatch]);
    const setError = (0, react_1.useCallback)((text) => dispatch((0, actions_1.setTxInteraction)({ mode: reducer_1.Interactions.error, text })), [dispatch]);
    const setNone = (0, react_1.useCallback)(() => dispatch((0, actions_1.setTxInteraction)({ mode: reducer_1.Interactions.none })), [dispatch]);
    return { setComplete, setPending, setError, setNone };
}
exports.useSetWalletInteractions = useSetWalletInteractions;
// returns a function that allows adding a popup
function useAddPopup() {
    const dispatch = (0, react_redux_1.useDispatch)();
    return (0, react_1.useCallback)((content, key) => {
        dispatch((0, actions_1.addPopup)({ content, key }));
    }, [dispatch]);
}
exports.useAddPopup = useAddPopup;
// returns a function that allows removing a popup via its key
function useRemovePopup() {
    const dispatch = (0, react_redux_1.useDispatch)();
    return (0, react_1.useCallback)((key) => {
        dispatch((0, actions_1.removePopup)({ key }));
    }, [dispatch]);
}
exports.useRemovePopup = useRemovePopup;
// get the list of active popups
function useActivePopups() {
    const list = (0, react_redux_1.useSelector)((state) => state.application.popupList);
    return (0, react_1.useMemo)(() => list.filter((item) => item.show), [list]);
}
exports.useActivePopups = useActivePopups;
const VISIBILITY_STATE_SUPPORTED = "visibilityState" in document;
function isWindowVisible() {
    return !VISIBILITY_STATE_SUPPORTED || document.visibilityState !== "hidden";
}
/**
 * Returns whether the window is currently visible to the user.
 */
function useIsWindowVisible() {
    const [focused, setFocused] = (0, react_1.useState)(isWindowVisible());
    const listener = (0, react_1.useCallback)(() => {
        setFocused(isWindowVisible());
    }, [setFocused]);
    (0, react_1.useEffect)(() => {
        if (!VISIBILITY_STATE_SUPPORTED)
            return undefined;
        document.addEventListener("visibilitychange", listener);
        return () => {
            document.removeEventListener("visibilitychange", listener);
        };
    }, [listener]);
    return focused;
}
exports.default = useIsWindowVisible;
