"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectionService = void 0;
const config_1 = require("@app/config");
const url_parser_1 = require("@utils/http/url-parser");
const http_service_1 = require("./http.service");
const routes_1 = require("./routes");
class CollectionService extends http_service_1.HttpService {
    constructor() {
        super(config_1.CONFIG.baseApiUrl);
        this.getCollectionList = (filter, page, size) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, url_parser_1.parseToUrlQuery)(Object.assign(Object.assign({}, filter), { page, size }));
            const { object } = yield this.httpClient.get(routes_1.API_ROUTES.getCollections(query));
            return object;
        });
        this.updateCollection = (jwt, data) => __awaiter(this, void 0, void 0, function* () {
            const headers = this.attachJwt(jwt);
            yield this.httpClient.post(routes_1.API_ROUTES.updateCollectionByAdmin(data.id), {
                headers,
                body: JSON.stringify(data),
            });
        });
    }
}
exports.collectionService = new CollectionService();
