"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitialConnect = exports.useEagerConnect = void 0;
const connectors_1 = require("@utils/connectors");
const core_1 = require("@web3-react/core");
const react_1 = require("react");
function useEagerConnect() {
    const { activate, active } = (0, core_1.useWeb3React)();
    const [tried, setTried] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const executor = () => __awaiter(this, void 0, void 0, function* () {
            const isAuthorized = yield connectors_1.injected.isAuthorized();
            if (isAuthorized) {
                try {
                    yield activate(connectors_1.injected, undefined, true);
                }
                catch (error) {
                    setTried(true);
                }
            }
            else {
                setTried(true);
            }
        });
        executor();
    }, []);
    (0, react_1.useEffect)(() => {
        if (!tried && active) {
            setTried(true);
        }
    }, [tried, active]);
    return tried;
}
exports.useEagerConnect = useEagerConnect;
const useInitialConnect = (context) => {
    const [forceUpdateContainer, setForceUpdate] = (0, react_1.useState)();
    useEagerConnect();
    (0, react_1.useEffect)(() => {
        window.addEventListener("connect-wallet", () => setForceUpdate({}));
        return () => {
            window.removeEventListener("connect-wallet", () => setForceUpdate({}));
        };
    }, []);
    (0, react_1.useEffect)(() => {
        sendWalletConnectedEventHandler();
    }, [context]);
    (0, react_1.useEffect)(() => {
        if (forceUpdateContainer) {
            connectWalletEventHandler();
        }
    }, [forceUpdateContainer]);
    const connectWalletEventHandler = () => context.active && sendWalletConnectedEventHandler();
    const sendWalletConnectedEventHandler = () => window.dispatchEvent(new CustomEvent("wallet-connected", { detail: context }));
};
exports.useInitialConnect = useInitialConnect;
