"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
const config_1 = require("@app/config");
const http_service_1 = require("./http.service");
const routes_1 = require("./routes");
class AuthService extends http_service_1.HttpService {
    constructor() {
        super(config_1.CONFIG.baseApiUrl);
        this.requestNonce = (account) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(routes_1.API_ROUTES.getNonceURL(account, ""));
            return object;
        });
        this.requestJwt = (body) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.post(routes_1.API_ROUTES.getJwtUrl(), {
                body: JSON.stringify(body),
            });
            return object;
        });
        this.getNonceString = (nonce) => `I'm use my nonce ${nonce}`;
    }
    getJwt(library, account) {
        return __awaiter(this, void 0, void 0, function* () {
            const nonce = yield this.requestNonce(account);
            const signature = yield this.signMessage(library, account, this.getNonceString(nonce));
            if (signature) {
                const authBodyRequest = {
                    signature,
                    wallet: account,
                };
                return this.requestJwt(authBodyRequest);
            }
            throw new Error("Login failed");
        });
    }
    signMessage(library, account, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield (library === null || library === void 0 ? void 0 : library.provider.request({
                method: "personal_sign",
                params: [data, account],
            }));
        });
    }
}
exports.AuthService = AuthService;
